const imageHumanitiePath = '/image/humanities'
const imageNaturePath = '/image/nature'
const imageCarouselHumanitie = '/image/carousel/humanities/'
const imageCarouselNature = '/image/carousel/nature/'
const imageCollectionCard = '/image/collection/card/'
//const BaseUrl = 'http://52.78.76.155:5050'
//const BaseUrl = `${window.location.protocol}//${window.location.hostname}:5050`;
const BaseUrl = `${window.location.protocol}//${window.location.hostname}`;

export const BannerImage = ''
/**
 * 까로의 공동체 생활 (DreamFruit)
 * 
 * 댕구의 몬스터 체포하기 (DreamMonsterArrest)
 * 
 * 수호의 던전 탐험기 (DreamDungeonExplorer)
 * 
 * 수호의 마법대전 (DreamMagicBattle)
 * 
 * 수호의 마을 지키기 (DreamTower)
 * 
 * 수호의 백만장자 되기 (DreamBoardGame)
 * 
 * 츄르아의 역사탐방 (DreamHistory)
 * 
 * 해링의 뛰기 훈련 (DreamRunning)
 * 
 * 공룡들의 던전 탐험기 (DinoDungeonExplorer)
 * 
 * 다이노 부화장 (DinoHatchery)
 * 
 * 다이노 타워 (DinoTower)
 * 
 * 다이노의 60초 소방대 (DinoFireFighter)
 * 
 * 다이노의 둥지만들기 (DinoBlock)
 * 
 * 다이노의 숨은 물건 찾기 (DinoHiddenObject)
 * 
 * 티노의 모험 (DinoCoding)
 * 
 * 티노의 여행 (DinoPhysics)
*/
export const PageInform = {
    humanities: {
        DreamFruit: {
            subject: "도덕",
            topTitle: "까로의<br/>공동체 생활",
            middleText: "블록을 터치하여 점수를<br/>획득하고, 퀴즈를 풀어 게임에<br/>도움이 되는 효과를 획득해<br/>높은 점수를 기록하는 게임",
            bottomText1: "세상을 더불어 살아가는데 필요한 기본적인 가치·덕목과 규범을 이해하고 도덕적 기능과 실천 능력을 함양할 수 있어요.",
            bottomText2: "도덕적 가치판단 퀴즈를 해결하면서 도덕적 가치판단 역량을 기르고 실생활에서 올바른 가치판단을 할 수 있어요.",
            bottomText3: "",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_Fruit.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamFruit/1.png`,
                `${imageCarouselHumanitie}/DreamFruit/2.png`,
                `${imageCarouselHumanitie}/DreamFruit/3.png`,
                `${imageCarouselHumanitie}/DreamFruit/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamFruit.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamFruit/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamFruit_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamFruit_Android_Ver.apk`,
        },

        DreamMonsterArrest: {
            subject: "국어",
            topTitle: "댕구의<br/>몬스터 체포하기",
            middleText: "국어 문법 퀴즈를 통해<br/>마을에 숨어있는 몬스터를 찾아<br/>체포하여 점수를 획득하는 게임",
            bottomText1: "문장성분을 이해하고 호응관계가 올바른 문장을 구성하는 방법을 알 수 있어요.",
            bottomText2: "다양한 상황에서 쓰이는 속담의 뜻을 알고 정확하고 효과적으로 속담을 활용할 수 있어요.",
            bottomText3: "올바른 높임 표현을 사용하는 경우와 방법을 알고 언어예절에 맞게 대화하는 방법을 배울 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_MonsterArrest.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamMonsterArrest/1.png`,
                `${imageCarouselHumanitie}/DreamMonsterArrest/2.png`,
                `${imageCarouselHumanitie}/DreamMonsterArrest/3.png`,
                `${imageCarouselHumanitie}/DreamMonsterArrest/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamMonsterArrest.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamMonsterArrest/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamMonsterArrest_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamMonsterArrest_Android_Ver.apk`,
        },

        DreamMagicBattle: {
            subject: "영어",
            topTitle: "수호의<br/>마법대전",
            middleText: "영어 어휘와 관련된 스킬을<br/>사용하여 몬스터를 무찌르고<br/>점수를 획득하는 게임",
            bottomText1: "주요 낱말(명사, 형용사, 동사)의 알파벳 철자를 알고 몬스터의 모습에 따라 변하는 낱말의 의미를 이해하면서 어휘를 익힐 수 있어요.",
            bottomText2: "몬스터를 잡기 위해 다양한 전략을 활용하면서 영어 학습과 동시에 두뇌 개발에 도움이 될 수 있어요.",
            bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적 학습을 할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_MagicBattle.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamMagicBattle/1.png`,
                `${imageCarouselHumanitie}/DreamMagicBattle/2.png`,
                `${imageCarouselHumanitie}/DreamMagicBattle/3.png`,
                `${imageCarouselHumanitie}/DreamMagicBattle/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamMagicBattle.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamMagicBattle/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamMagicBattle_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamMagicBattle_Android_Ver.apk`,
        },

        DreamTower: {
            subject: "도덕",
            topTitle: "수호의<br/>마을 지키기",
            middleText: "자신 및 타인과의 다양한 관계에서<br/>발생하는 갈등을 도덕적으로<br/>해결하려는 자세와 태도에 대하여<br/>학습할 수 있는 디펜스 퀴즈 게임",
            bottomText1: "도덕적 가치를 이해하고 퀴즈를 풀며 문제를 해결할 수 있어요.",
            bottomText2: "도덕적 가치판단의 반복학습을 통해 내면화할 수 있는 역량을 길러 실제 생활에서 올바른 가치판단을 할 수 있어요.",
            bottomText3: "",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_Tower.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamTower/1.png`,
                `${imageCarouselHumanitie}/DreamTower/2.png`,
                `${imageCarouselHumanitie}/DreamTower/3.png`,
                `${imageCarouselHumanitie}/DreamTower/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamTower.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamTower/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamTower_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamTower_Android_Ver.apk`,
        },

        DreamBoardGame: {
            subject: "사회",
            topTitle: "수호의<br/>백만장자 되기",
            middleText: "경제 활동을 통해 재산을 가장<br/>많이 획득하는 사람이 승리하며<br/>경제 활동 및 지리에 대하여<br/>학습할 수 있는 게임",
            bottomText1: "우리나라와 세계 여러 나라 도시의 지리적 특징을 활용하여 문제를 해결할 수 있어요.",
            bottomText2: "합리적인 의사결정 과정을 통해 도시 경영을 하며 문제를 해결할 수 있어요.",
            bottomText3: "우리나라, 세계 여러 도시들의 지리적 정보를 바탕으로 문제를 해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_BoardGame.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamBoardGame/1.png`,
                `${imageCarouselHumanitie}/DreamBoardGame/2.png`,
                `${imageCarouselHumanitie}/DreamBoardGame/3.png`,
                `${imageCarouselHumanitie}/DreamBoardGame/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamBoardGame.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamBoardGame/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamBoardGame_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamBoardGame_Android_Ver.apk`,
        },

        DreamHistory: {
            subject: "사회",
            topTitle: "츄르아의<br/>역사탐방",
            middleText: "시대별로 움직이는 그림들 속에<br/>숨겨진 우리나라의 대표적인<br/>유물과 유적, 인물을 찾는<br/>숨은그림찾기 게임",
            bottomText1: "우리나라의 대표 유물과 인물을 살펴보면서 역사 속 사회 변화의 특징과 중요한 사건을 이해할 수 있어요.",
            bottomText2: "우리나라의 역사적 사실에 대해 관심을 가지고 문화유산을 소중히 여기는 마음을 기를 수 있어요.",
            bottomText3: "역사적 사실을 바탕으로 다양한 문제를 해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_History.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamHistory/1.png`,
                `${imageCarouselHumanitie}/DreamHistory/2.png`,
                `${imageCarouselHumanitie}/DreamHistory/3.png`,
                `${imageCarouselHumanitie}/DreamHistory/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamHistory.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamHistory/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamHistory_PC_Ver_1.0.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamHistory_Android_Ver_1.0.apk`,
        },

        DreamRunning: {
            subject: "영어",
            topTitle: "해링의<br/>뛰기 훈련",
            middleText: "방해물을 피하고<br/>달리기 도중에 출제되는<br/>영어 퀴즈를 풀고<br/>점수를 기록하는 게임",
            bottomText1: "낱말을 읽고, 완성하는 활동을 통해 이미 알고 있는 낱말의 의미를 확인하고 처음 접하는 낱말을 재미있게 익힐 수 있어요.",
            bottomText2: "문제의 정답을 맞추며 점수를 높이고 흥미를 느끼면서 자연스럽게 반복학습을 할 수 있어요.",
            bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적 학습을 할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_Running.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamRunning/1.png`,
                `${imageCarouselHumanitie}/DreamRunning/2.png`,
                `${imageCarouselHumanitie}/DreamRunning/3.png`,
                `${imageCarouselHumanitie}/DreamRunning/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamRunning.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamRunning/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamRunning_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/2_Humanities/DreamRunning_Android_Ver.apk`,
        },

        DreamDungeonExplorer: {
            subject: "국어",
            topTitle: "수호의<br/>던전 탐험기",
            middleText: "던전을 조사하여 단서를 획득하고,<br/>국어 문학 퀴즈를 풀어<br/>보물 상자를 획득하는 게임",
            bottomText1: "이야기의 흐름을 파악하고 이어질 내용을 상상하고 표현할 수 있어요.",
            bottomText2: "문학 작품에서 재미나 감동적인 부분을 찾을 수 있어요.",
            bottomText3: "다양한 문학 작품을 경험하고 작품에 대한 생각이나 느낌을 표현할 수 있어요.",
            bottomText4: "작품 속 세계와 현실 속 세계를 비교하고 비유적인 표현의 특성과 효과를 이해할 수 있어요.",
            imageTitle: "/image/humanities/DreamTitle_DungeonExplorer.png",
            imageSlide: [
                `${imageCarouselHumanitie}/DreamDungeonExplorer/1.png`,
                `${imageCarouselHumanitie}/DreamDungeonExplorer/2.png`,
                `${imageCarouselHumanitie}/DreamDungeonExplorer/3.png`,
                `${imageCarouselHumanitie}/DreamDungeonExplorer/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DreamDungeonExplorer.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DreamDungeonExplorer/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/2_Humanities/DreamDungeonExplorer_PC_Ver.zip`,
            androidDownload: ``,
        },
        // DreamCity: {
        //     topTitle: "만들어요<br/>꿈의 도시",
        //     middleText: "방해물을 피하고<br/>달리기 도중에 출제되는<br/>영어 퀴즈를 풀고<br/>점수를 기록하는 게임",
        //     bottomText1: "낱말을 읽고, 완성하는 활동을 통해 이미 알고 있는 낱말의<br/>의미를 확인하고 처음 접하는 낱말을 재미있게 익힐 수<br/>있어요.",
        //     bottomText2: "문제의 정답을 맞추며 점수를 높이고 흥미를 느끼면서<br/>자연스럽게 반복학습을 할 수 있어요.",
        //     bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적<br/>학습을 할 수 있어요.",
        //     bottomText4: "",
        //     imageTitle: "/image/humanities/DreamTitle_City.png",
        //     imageSlide: [
        //         "/image/humanities/DreamTitle_Fruit.png",
        //         "/image/humanities/DreamTitle_MonsterArrest.png",
        //         "/image/humanities/DreamTitle_DungeonExplorer.png",
        //         "/image/humanities/DreamTitle_MagicBattle.png",
        //     ],
        //     gameStart: "",
        //     windowDownload: "",
        //     androidDownload: "",
        // },
        // DreamFantasia: {
        //     topTitle: "드림<br/>판타지아",
        //     middleText: "방해물을 피하고<br/>달리기 도중에 출제되는<br/>영어 퀴즈를 풀고<br/>점수를 기록하는 게임",
        //     bottomText1: "낱말을 읽고, 완성하는 활동을 통해 이미 알고 있는 낱말의<br/>의미를 확인하고 처음 접하는 낱말을 재미있게 익힐 수<br/>있어요.",
        //     bottomText2: "문제의 정답을 맞추며 점수를 높이고 흥미를 느끼면서<br/>자연스럽게 반복학습을 할 수 있어요.",
        //     bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적<br/>학습을 할 수 있어요.",
        //     bottomText4: "",
        //     imageTitle: "/image/humanities/DreamTitle_Fantasia.png",
        //     imageSlide: [
        //         "/image/humanities/DreamTitle_Fruit.png",
        //         "/image/humanities/DreamTitle_MonsterArrest.png",
        //         "/image/humanities/DreamTitle_DungeonExplorer.png",
        //         "/image/humanities/DreamTitle_MagicBattle.png",
        //     ],
        //     gameStart: "",
        //     windowDownload: "",
        //     androidDownload: "",
        // },
    },
    nature: {

        DinoHatchery: {
            subject: "수학",
            topTitle: "다이노<br/>부화장",
            middleText: "수와 연산영역을 학년별로<br/>4개의 스테이지로 구성하여<br/>퀴즈를 퍼즐 형식으로<br/>해결하는 게임",
            bottomText1: "3~6학년 수와 연산 문제를 해결할 수 있어요.",
            bottomText2: "핵심 연산 단원의 문제를 반복적으로 해결하며 연산 능력을 기를 수 있어요.",
            bottomText3: "연산 문제와 답에 해당되는 블록을 찾아 선택하면, 일정 시간 동안 높은 점수를 획득하며 아기 공룡을 부화시킬 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Hatchery.png",
            imageSlide: [
                `${imageCarouselNature}/DinoHatchery/1.png`,
                `${imageCarouselNature}/DinoHatchery/2.png`,
                `${imageCarouselNature}/DinoHatchery/3.png`,
                `${imageCarouselNature}/DinoHatchery/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoHatchery.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoHatchery/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoHatchery_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoHatchery_Android_Ver.apk`,
        },

        DinoFireFighter: {
            subject: "과학",
            topTitle: "다이노의<br/>60초 소방대",
            middleText: "소방관이 되어 직접 화재를 진압하고<br/>공룡을 대피시키는 과정에서<br/>소방 안전 교육 이해, 화재 상황시 발생하는<br/>생활 속 화학 이론을 체험하는 게임",
            bottomText1: "연소와 소화, 화재 대피를 위한 도구와 장소 등에 대한 설명을 통해 연소와 소화, 화재 예방에 관련된 내용을 알 수 있어요.",
            bottomText2: "소화기 사용 방법 및 보관 방법과 화재시 행동 요령, 대피방법 등의 안전 대책을 익힐 수 있어요.",
            bottomText3: "장소별, 상황별 화재의 위험성과 화재 예방을 위한 행동 수칙을 알 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_FireFighter.png",
            imageSlide: [
                `${imageCarouselNature}/DinoFireFighter/1.png`,
                `${imageCarouselNature}/DinoFireFighter/2.png`,
                `${imageCarouselNature}/DinoFireFighter/3.png`,
                `${imageCarouselNature}/DinoFireFighter/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoFireFighter.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoFireFighter/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoFireFighter_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoFireFighter_Android_Ver.apk`,
        },

        DinoBlock: {
            subject: "과학",
            topTitle: "다이노의<br/>둥지만들기",
            middleText: "도형에 관한 퀴즈를 풀며<br/>해당 도형을 획득하고 수집하여<br/>공룡의 둥지를 짓는 게임",
            bottomText1: "3~6학년 도형 영역의 문제를 해결할 수 있어요.",
            bottomText2: "도형 단원의 문제를 반복적으로 해결하며 공간감각을 기를 수 있어요.",
            bottomText3: "도형 문제를 해결하여 블록을 획득한 후, 획득한 블록을 이용하여 다양한 가구로 둥지를 꾸밀 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Block.png",
            imageSlide: [
                `${imageCarouselNature}/DinoBlock/1.png`,
                `${imageCarouselNature}/DinoBlock/2.png`,
                `${imageCarouselNature}/DinoBlock/3.png`,
                `${imageCarouselNature}/DinoBlock/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoBlock.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoBlock/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoBlock_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoBlock_Android_Ver.apk`,
        },

        DinoHiddenObject: {
            subject: "실과",
            topTitle: "다이노의<br/>숨은 물건 찾기",
            middleText: "움직이는 그림들 속에<br/>숨어있는 실과 학습 준비에 해당하는<br/>물품들을 모두 찾아 실과 체험까지<br/>진행하는 방식의 게임",
            bottomText1: "실천적 문제 해결을 하여 자립적인 삶을 영위하고, 기술에 대한 실천적 학습 경험을 통해 기술적 지식, 기능, 태도를 기를 수 있어요.",
            bottomText2: "방 안 곳곳에 숨어 있는 물건을 찾으며, 문제를 해결하는 과정에서 개념을 학습할 수 있어요.",
            bottomText3: "숨은 물건 찾기 게임 활동을 직접 체험함으로써 학습자의 흥미를 유발하고, 쉽고 재미있게 학습할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_HiddenObject.png",
            imageSlide: [
                `${imageCarouselNature}/DinoHiddenObject/1.png`,
                `${imageCarouselNature}/DinoHiddenObject/2.png`,
                `${imageCarouselNature}/DinoHiddenObject/3.png`,
                `${imageCarouselNature}/DinoHiddenObject/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoHiddenObject.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoHiddenObject/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoHiddenObject_PC_Ver_1.0.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoHiddenObject_Android_Ver_1.0.apk`,
        },

        DinoTower: {
            subject: "과학",
            topTitle: "다이노 타워",
            middleText: "타워 디펜스 게임과 퀴즈를 접목해<br/>제시되는 생물 관련 퀴즈를 풀어<br/>재화를 확보하고, 공룡을 배치하여<br/>둥지를 지키는 게임",
            bottomText1: "문제 풀이 활동을 통해 과학적 의사소통능력을 함양할 수 있어요.",
            bottomText2: "과학 문제를 해결하는 과정에서 학생들이 과학 문제에 흥미를 가지고 적극적으로 참여할 수 있어요.",
            bottomText3: "각 단원에서 배웠던 내용을 문제풀이를 통해 확인하고 복습할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Tower.png",
            imageSlide: [
                `${imageCarouselNature}/DinoTower/1.png`,
                `${imageCarouselNature}/DinoTower/2.png`,
                `${imageCarouselNature}/DinoTower/3.png`,
                `${imageCarouselNature}/DinoTower/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoTower.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoTower/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoTower_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoTower_Android_Ver.apk`,
        },

        DinoCoding: {
            subject: "코딩",
            topTitle: "티노의 모험",
            middleText: "컴퓨팅 사고력 증진에 효과적인<br/>블록 코딩을 통해 공룡을<br/>목적지로 이동하는 게임",
            bottomText1: "순차, 선택, 반복의 원리와 구조를 알 수 있어요.",
            bottomText2: "목표 지점까지 동물을 옮기는 것으로 다양한 조건에 유의하여 코딩을 실행할 수 있어요.",
            bottomText3: "절차적 사고를 익힌 후 일상생활에서 문제를 해결하고 유용하게 활용하는 태도를 지닐 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Coding.png",
            imageSlide: [
                `${imageCarouselNature}/DinoCoding/1.png`,
                `${imageCarouselNature}/DinoCoding/2.png`,
                `${imageCarouselNature}/DinoCoding/3.png`,
                `${imageCarouselNature}/DinoCoding/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoCoding.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoCoding/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoCoding_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoCoding_Android_Ver.apk`,
        },

        DinoPhysics: {
            subject: "과학",
            topTitle: "티노의 여행",
            middleText: "다양한 물체의 성질을 가진<br/>공룡알을 이동시키며,<br/>물리 법칙 및 물체들이 가진 성질을<br/>이해하는 퍼즐 게임",
            bottomText1: "물체를 이루고 있는 물질의 성질을 파악하고 물질의 성질을 이용해 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText2: "수평대의 무게 균형을 이루는 방법을 생각하고 파악하여 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText3: "빛의 직진 및 거울의 입사각과 반사각이 같은 것과 전기 회로의 조건을 이용해서 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Physics.png",
            imageSlide: [
                `${imageCarouselNature}/DinoPhysics/1.png`,
                `${imageCarouselNature}/DinoPhysics/2.png`,
                `${imageCarouselNature}/DinoPhysics/3.png`,
                `${imageCarouselNature}/DinoPhysics/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoPhysics.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoPhysics/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoPhysics_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/1_Nature/DinoPhysics_Android_Ver.apk`,
        },

        DinoDungeonExplorer: {
            subject: "수학",
            topTitle: "공룡들의<br/>던전 탐험기",
            middleText: "숨겨진 보물을 찾고,<br/>던전을 탈출하는 과정에서<br/>제시되는 수학 문제를 푸는 형태로<br/>일종의 방 탈출 게임",
            bottomText1: "학생들이 수학 문제에 흥미를 가지고 문제풀이에 적극적으로 참여할 수 있어요.",
            bottomText2: "게임의 미션 해결을 위해 해결 단서를 공유하는 과정에서 의사소통 능력과 협력적 문제 해결력을 신장할 수 있어요.",
            bottomText3: "생활 속에서 다양한 상황을 규칙성, 자료의 가능성의 눈으로 바라보고 해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_DungeonExplorer.png",
            imageSlide: [
                `${imageCarouselNature}/DinoDungeonExplorer/1.png`,
                `${imageCarouselNature}/DinoDungeonExplorer/2.png`,
                `${imageCarouselNature}/DinoDungeonExplorer/3.png`,
                `${imageCarouselNature}/DinoDungeonExplorer/4.png`,
            ],
            imageCollection: `${imageCollectionCard}DinoDungeonExplorer.png`,
            gameStart: `${BaseUrl}/00_Web_Ver/DinoDungeonExplorer/index.html`,
            windowDownload: `${BaseUrl}/01_PC_Ver/1_Nature/DinoDungenExplorer_PC_Ver.zip`,
            androidDownload: ``,
        },
        // DinoKingdom: {
        //     topTitle: "다이노 킹덤",
        //     middleText: "다양한 물체의 성질을 가진<br/>공룡알을 이동시키며,<br/>물리 법칙 및 물체들이 가진 성질을<br/>이해하는 퍼즐 게임",
        //     bottomText1: "물체를 이루고 있는 물질의 성질을 파악하고 물질의 성질을 이용해<br/>주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
        //     bottomText2: "수평대의 무게 균형을 이루는 방법을 생각하고 파악하여 주어진 퍼즐을<br/>푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
        //     bottomText3: "빛의 직진 및 거울의 입사각과 반사각이 같은 것과 전기 회로의 조건을<br/>이용해서 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를<br/>해결할 수 있어요.",
        //     bottomText4: "",
        //     imageTitle: "/image/nature/DinoTitle_Kingdom.png",
        //     imageSlide: [
        //         "/image/humanities/DreamTitle_Fruit.png",
        //         "/image/humanities/DreamTitle_MonsterArrest.png",
        //         "/image/humanities/DreamTitle_DungeonExplorer.png",
        //         "/image/humanities/DreamTitle_MagicBattle.png",
        //     ],
        //     gameStart: "",
        //     windowDownload: "",
        //     androidDownload: "",
        // },
        // HelloLuca: {
        //     topTitle: "안녕 루카",
        //     middleText: "다양한 물체의 성질을 가진<br/>공룡알을 이동시키며,<br/>물리 법칙 및 물체들이 가진 성질을<br/>이해하는 퍼즐 게임",
        //     bottomText1: "물체를 이루고 있는 물질의 성질을 파악하고 물질의 성질을 이용해<br/>주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
        //     bottomText2: "수평대의 무게 균형을 이루는 방법을 생각하고 파악하여 주어진 퍼즐을<br/>푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
        //     bottomText3: "빛의 직진 및 거울의 입사각과 반사각이 같은 것과 전기 회로의 조건을<br/>이용해서 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를<br/>해결할 수 있어요.",
        //     bottomText4: "",
        //     imageTitle: "/image/nature/Hello_Luca.png",
        //     imageSlide: [
        //         "/image/humanities/DreamTitle_Fruit.png",
        //         "/image/humanities/DreamTitle_MonsterArrest.png",
        //         "/image/humanities/DreamTitle_DungeonExplorer.png",
        //         "/image/humanities/DreamTitle_MagicBattle.png",
        //     ],
        //     gameStart: "",
        //     windowDownload: "",
        //     androidDownload: "",
        // }
    },
    midcore: {
        DreamCity: {
            subject: "인문",
            topTitle: "만들어요<br/>꿈의 도시",
            middleText: "방해물을 피하고<br/>달리기 도중에 출제되는<br/>영어 퀴즈를 풀고<br/>점수를 기록하는 게임",
            bottomText1: "낱말을 읽고, 완성하는 활동을 통해 이미 알고 있는 낱말의<br/>의미를 확인하고 처음 접하는 낱말을 재미있게 익힐 수<br/>있어요.",
            bottomText2: "문제의 정답을 맞추며 점수를 높이고 흥미를 느끼면서<br/>자연스럽게 반복학습을 할 수 있어요.",
            bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적<br/>학습을 할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_City.png",
            imageSlide: [
                "/image/humanities/DreamTitle_Fruit.png",
                "/image/humanities/DreamTitle_MonsterArrest.png",
                "/image/humanities/DreamTitle_DungeonExplorer.png",
                "/image/humanities/DreamTitle_MagicBattle.png",
            ],
            imageCollection: `${imageCollectionCard}DreamCity.png`,
            gameStart: ``,
            windowDownload: `${BaseUrl}/01_PC_Ver/3_MidCore/DreamCity_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/3_MidCore/DreamCity_Android_Ver.apk`,
        },
        DreamFantasia: {
            subject: "인문",
            topTitle: "드림<br/>판타지아",
            middleText: "방해물을 피하고<br/>달리기 도중에 출제되는<br/>영어 퀴즈를 풀고<br/>점수를 기록하는 게임",
            bottomText1: "낱말을 읽고, 완성하는 활동을 통해 이미 알고 있는 낱말의<br/>의미를 확인하고 처음 접하는 낱말을 재미있게 익힐 수<br/>있어요.",
            bottomText2: "문제의 정답을 맞추며 점수를 높이고 흥미를 느끼면서<br/>자연스럽게 반복학습을 할 수 있어요.",
            bottomText3: "나의 수준에 맞는 스테이지를 선택하면서 자기주도적<br/>학습을 할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/humanities/DreamTitle_Fantasia.png",
            imageSlide: [
                "/image/humanities/DreamTitle_Fruit.png",
                "/image/humanities/DreamTitle_MonsterArrest.png",
                "/image/humanities/DreamTitle_DungeonExplorer.png",
                "/image/humanities/DreamTitle_MagicBattle.png",
            ],
            imageCollection: `${imageCollectionCard}DreamFantasia.png`,
            gameStart: ``,
            windowDownload: `${BaseUrl}/01_PC_Ver/3_MidCore/DreamFantasia_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/3_MidCore/DreamFantasia_Android_Ver.apk`,
        },
        DinoKingdom: {
            subject: "자연",
            topTitle: "다이노 킹덤",
            middleText: "다양한 물체의 성질을 가진<br/>공룡알을 이동시키며,<br/>물리 법칙 및 물체들이 가진 성질을<br/>이해하는 퍼즐 게임",
            bottomText1: "물체를 이루고 있는 물질의 성질을 파악하고 물질의 성질을 이용해<br/>주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText2: "수평대의 무게 균형을 이루는 방법을 생각하고 파악하여 주어진 퍼즐을<br/>푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText3: "빛의 직진 및 거울의 입사각과 반사각이 같은 것과 전기 회로의 조건을<br/>이용해서 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를<br/>해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/DinoTitle_Kingdom.png",
            imageSlide: [
                "/image/humanities/DreamTitle_Fruit.png",
                "/image/humanities/DreamTitle_MonsterArrest.png",
                "/image/humanities/DreamTitle_DungeonExplorer.png",
                "/image/humanities/DreamTitle_MagicBattle.png",
            ],
            imageCollection: `${imageCollectionCard}DinoKingdom.png`,
            gameStart: ``,
            windowDownload: `${BaseUrl}/01_PC_Ver/3_MidCore/DinoAdventure_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/3_MidCore/DinoAdventure_Android_Ver.apk`,
        },
        HelloLuca: {
            subject: "자연",
            topTitle: "안녕 루카",
            middleText: "다양한 물체의 성질을 가진<br/>공룡알을 이동시키며,<br/>물리 법칙 및 물체들이 가진 성질을<br/>이해하는 퍼즐 게임",
            bottomText1: "물체를 이루고 있는 물질의 성질을 파악하고 물질의 성질을 이용해<br/>주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText2: "수평대의 무게 균형을 이루는 방법을 생각하고 파악하여 주어진 퍼즐을<br/>푸는 방법을 설계하고 수정하며 문제를 해결할 수 있어요.",
            bottomText3: "빛의 직진 및 거울의 입사각과 반사각이 같은 것과 전기 회로의 조건을<br/>이용해서 주어진 퍼즐을 푸는 방법을 설계하고 수정하며 문제를<br/>해결할 수 있어요.",
            bottomText4: "",
            imageTitle: "/image/nature/Hello_Luca.png",
            imageSlide: [
                "/image/humanities/DreamTitle_Fruit.png",
                "/image/humanities/DreamTitle_MonsterArrest.png",
                "/image/humanities/DreamTitle_DungeonExplorer.png",
                "/image/humanities/DreamTitle_MagicBattle.png",
            ],
            imageCollection: `${imageCollectionCard}HelloLuca.png`,
            gameStart: ``,
            windowDownload: `${BaseUrl}/01_PC_Ver/3_MidCore/HelloLuca_PC_Ver.zip`,
            androidDownload: `${BaseUrl}/02_Android_Ver/3_MidCore/HelloLuca_Android_Ver.apk`,
        }
    }
}

