import { Box, Card, Link, styled, Typography } from "@mui/material";

import React from "react";


export const CollectionCard = ({ inform, tap, theme }) => {
    return (
        <Card sx={{ width: '300px', height: '350px', m: 2, borderRadius: '25px' }}>
            <CardHeader type={tap} data={inform} theme={theme} >
                <CardTitle>{inform.topTitle.replace('<br/>', ' ')}</CardTitle>
                <CardSubjectContainer>
                    <CardSubject type={tap} theme={theme}>{inform.subject}</CardSubject>
                </CardSubjectContainer>
            </CardHeader>
            <CardImageContainer>
                <CardImage src={inform.imageTitle} />
            </CardImageContainer>
            <CardDownloadContainer type={tap} theme={theme}>
                <Link href={inform.windowDownload}>
                    <CardDownloadButton component="img" src="/image/collection/card/PC_Download.png" alt="pc_download" />
                </Link>
                {inform.androidDownload &&
                    <Link href={inform.androidDownload}>
                        <CardDownloadButton component="img" src="/image/collection/card/Android_Download.png" alt="android_download" />
                    </Link>
                }

            </CardDownloadContainer>
            <CardGameStartContainer>
                {inform.gameStart &&
                    <CardGameStartButton href={inform.gameStart} type={tap} theme={theme}>
                        <Box component="img" src="/image/collection/Game_Start.png" sx={{ width: '45%' }} />
                    </CardGameStartButton>
                }
            </CardGameStartContainer>
        </Card>
    );
}

export default CollectionCard;

const CardHeader = styled(Box)(({ data, type, theme }) => ({
    height: '50px',
    width: '100%',
    background: `linear-gradient(to right, ${theme[type].origin}, ${theme[type].light})`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const CardTitle = styled(Typography)(() => ({
    color: 'white',
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: '15px',
    marginTop: '3px',
    textShadow: '2px 2px 2px rgba(0,0,0,0.4)'
}));

const CardSubjectContainer = styled(Box)(() => ({
    backgroundColor: 'white',
    width: '60px',
    marginRight: '15px',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2px',
    boxShadow: '2px 2px 2px rgba(0,0,0,0.4)'
}));

const CardSubject = styled(Typography)(({ type, theme }) => ({
    letterSpacing: '2px',
    fontWeight: 'bold',
    fontSize: '15px',
    color: theme[type].origin
}));

const CardImageContainer = styled(Box)(({ }) => ({
    width: '100%',
    height: '190px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const CardImage = styled('img')(({ }) => ({
    width: '250px'
}));

const CardDownloadContainer = styled(Box)(({ type, theme }) => ({
    width: '100%',
    height: '35px',
    backgroundColor: theme[type].light,
    display: 'flex',
    justifyContent: 'space-around',
}));

const CardDownloadButton = styled(Box)(({ }) => ({
    height: '25px',
    margin: '5px 10px',
    cursor: 'pointer',
}));

const CardGameStartContainer = styled(Box)(({ }) => ({
    height: '75px',
    width: '100%',
    backgroundColor: 'rgba(240,238,239,1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const CardGameStartButton = styled(Link)(({ type, theme }) => ({
    cursor: 'pointer',
    width: '80%',
    height: '50px',
    backgroundColor: theme[type].light,
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `0px 3px ${theme[type].dark}`
}));