import React from "react";
import { Box } from '@mui/material';
import { Link } from "react-router-dom";

const CARD_OPTION = {
    humanities: {
        cardContainer: "/image/Humanities_Back.png",
        cardTitle: "/image/human.png",
        cardImage: "/image/Image_01.png",
        cardMoveContainer: "/image/Button_Blue.png",
        cardMove: "",

    },
    nature: {
        cardContainer: "/image/Nature_Back.png",
        cardTitle: "/image/nature.png",
        cardImage: "/image/Image_02.png",
        cardMoveContainer: "/image/Button_Green.png",
        cardMove: "",
    },
    creative: {
        cardContainer: "/image/Creative_Back.png",
        cardTitle: "/image/create.png",
        cardImage: "/image/Image_04.png",
        cardMoveContainer: "/image/Button_Orange.png",
        cardMove: "",
    }
}

export const HomeCard = ({ param }) => {

    return (
        <Box sx={{ width: '400px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box
                component="img"
                src={CARD_OPTION[param].cardContainer}
                sx={{ width: 'inherit' }}
            >
            </Box>
            <Box
                sx={{ position: 'absolute', top: 25, left: 40, zIndex: 1, width: '50px' }}
                component="img"
                src={CARD_OPTION[param].cardTitle}

            />
            <Box
                component="img"
                src={CARD_OPTION[param].cardImage}
                sx={{ position: 'absolute', top: 62, left: 14, zIndex: 1, width: '369px' }}
            />
            <Box
                sx={{ width: '250px', position: 'absolute', zIndex: 1, top: 370, left: 75 }}
            >
            {
                param !== 'creative' ? 
                <Link
                    to={param !== 'creative' ? '/collection' : ''}
                    state={{option : param}}
                >
                    <Box
                        sx={{ cursor: 'pointer', width: '100%' }}
                        component="img"
                        src={CARD_OPTION[param].cardMoveContainer}
                    />
                    <Box
                        component="img"
                        sx={{ cursor: 'pointer', width: '85px', position: 'absolute', top: 20, left: 30 }}
                        src="/image/move.png"
                    />
                    <Box
                        component="img"
                        sx={{ cursor: 'pointer', width: '25px', position: 'absolute', top: 15, left: 205 }}
                        src="/image/Arrow_Right.png"
                    />
                </Link>
                :
                <a
                    href="https://edu.enpgames.com/member/login"
                    rel="noreferrer"
                >
                    <Box
                        sx={{ cursor: 'pointer', width: '100%' }}
                        component="img"
                        src={CARD_OPTION[param].cardMoveContainer}
                    />
                    <Box
                        component="img"
                        sx={{ cursor: 'pointer', width: '85px', position: 'absolute', top: 20, left: 30 }}
                        src="/image/move.png"
                    />
                    <Box
                        component="img"
                        sx={{ cursor: 'pointer', width: '25px', position: 'absolute', top: 15, left: 205 }}
                        src="/image/Arrow_Right.png"
                    />
                </a>
            }

            </Box>

        </Box>
    );
}

export default HomeCard;
