import React from "react";
import { Box } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import HomeCard from "../component/HomeCard";

export default function Home() {
  return (
    <Box sx={{flex: 1, margin: 3}}>
      <Box  sx={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', height: '100%', flexWrap: 'wrap', maxHeight: '500px', overflow: 'auto'}}>
        <HomeCard param={"humanities"} />
        <HomeCard param={"nature"} />
        <HomeCard param={"creative"} />
      </Box>
    </Box>
  );
}

