import React from "react";
import { Box } from '@mui/material';
import { styled } from '@mui/material';
import { Outlet } from "react-router-dom";


export const PageLayout = () => {
    return (
        <PageContainer>
            <PageHeader>
                <a href="https://www.kocca.kr/"
                    rel="noreferrer">
                <HeaderLogo
                    component="img"
                    src="/image/Logo.png"
                    alt="KoccaEduGameITDA_logo"
                    sx={{ marginLeft: 3, marginTop: 3  }}
                />
                </a>

            </PageHeader>
            <Outlet />
            <PageFooter>
                <Box sx={{ margin: 3, flexWrap: 'nowrap', color: 'white', fontFamily: 'sans-serif', fontSize: '1em' }}>
                    전체 페이지를 포함한 교육용 게임 콘텐츠는 인용하실 때 반드시 출처를 밝혀주시기 바랍니다.<br />

                    58326 전라남도 나주시 교육길 35(빛가람동 351) 한국콘텐츠진흥원 (문의) 061-900-6511, 6510
                </Box>
                <Box sx={{ margin: 3, flexWrap: 'nowrap', color: 'white', fontFamily: 'sans-serif', fontSize: '0.9em' }}>
                    Copyright 2023. KOREA CREATIVE CONTENT AGENCY ALL RIGHTS RESERVED.
                </Box>
            </PageFooter>
        </PageContainer>
    )
}

export default PageLayout;

const PageContainer = styled('div')(({ theme }) => ({
    backgroundImage: 'url("/image/BG.png")',
    width: '100%',
    '@media (max-width: 500px)': {

    },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',


}));

const PageHeader = styled('div')(() => ({
    width: '100%',
}));

const HeaderLogo = styled('img')(() => ({
    margin: 3,
    maxWidth: '400px',
    '@media (max-width: 500px)': {
        width: '200px'
    },
}));

const PageFooter = styled('div')(() => ({
    width: '100%',

}));