import React from "react";
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import FindData from "../util/findData";



export default function GameInform(query) {
  const data = FindData(query);
  const imageSlides = data.imageSlide.map((image) => (
    <div key={image} style={{ textAlign: 'center' }}>
      <img src={image} alt={image.alt} style={{ padding: 0 }} />
    </div>
  ));

  return (
    <>

      <Box sx={{
        bgcolor: 'white', justifyContent: 'center',
        alignItems: 'center', textAlign: 'center', width: '100%'
      }}>
        <img src={data.imageTitle} style={{ display: 'block', margin: '0 auto', paddingTop: 30, paddingBottom: 30, objectFit: 'contain', width: '90%' }} />
        <ul style={{ width: '100%', lineHeight: 2, marginTop: 20, marginBottom: 20 }}>
          <li><a href={data.gameStart}><img src="./image/start.png" width='60%' /></a></li>
          <li><a href={data.windowDownload}><img src="./image/window.png" width='60%' /></a></li>
          <li><a href={data.androidDownload}><img src="./image/android.png" width='60%' /></a></li>
        </ul>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingBottom: 10, marginBottom: '10px' }}>
          <img src="/image/mcst.png" style={{ width: '40%', objectFit: 'contain' }} />
          <img src="/image/contentagency.png" style={{ width: '37%', objectFit: 'contain' }} />
        </div>
      </Box>
      {data &&
        data.middleText.split('<br/>').map((item) =>
          <TextBox theme={query.query.type} key={item}>{item}</TextBox>
        )
      }

      <Box sx={{ height: '10px' }} />
      <Carousel className="slide" autoPlay={true} showArrows={true} infiniteLoop={true} showStatus={false} showThumbs={false}>
        {imageSlides}
      </Carousel>


      <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row', width: '100%', marginTop: '10px' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '10px 0px 10px 0px', color: '#411D87' }}>
          이 게임을 하면..
        </Typography>
      </Box>

      {data?.bottomText1 &&
        <BottomTextBox key={data.bottomText1} theme={query.query.type} sx={{ textIndent: '-8px', marginLeft: '8px' }}>&#183; {data.bottomText1}</BottomTextBox>
      }
      {data?.bottomText2 &&
        <BottomTextBox key={data.bottomText2} theme={query.query.type} sx={{ textIndent: '-8px', marginLeft: '8px' }}>&#183; {data.bottomText2}</BottomTextBox>
      }
      {data?.bottomText3 &&
        <BottomTextBox key={data.bottomText3} theme={query.query.type} sx={{ textIndent: '-8px', marginLeft: '8px' }}>&#183; {data.bottomText3}</BottomTextBox>
      }
      {data?.bottomText4 &&
        <BottomTextBox key={data.bottomText4} theme={query.query.type} sx={{ textIndent: '-8px', marginLeft: '8px' }}>&#183; {data.bottomText4}</BottomTextBox>
      }

      {/* {data?.bottomText1 &&
        data.bottomText1.split('<br/>').map((value, index) => {
          if (index === 0) { return <BottomTextBox key={value} theme={query.query.type}>&#183; {value}</BottomTextBox>; }
          return <BottomTextBox key={value} theme={query.query.type}>&nbsp; {value}</BottomTextBox>;
        })
      } */}
      {/* {data?.bottomText2 &&
        data.bottomText2.split('<br/>').map((value, index) => {
          if (index === 0) { return <BottomTextBox key={value} theme={query.query.type}>&#183; {value}</BottomTextBox>; }
          return <BottomTextBox key={value} theme={query.query.type}>&nbsp; {value}</BottomTextBox>;
        })
      }
      {data?.bottomText3 &&
        data.bottomText3.split('<br/>').map((value, index) => {
          if (index === 0) { return <BottomTextBox key={value} theme={query.query.type}>&#183; {value}</BottomTextBox>; }
          return <BottomTextBox key={value} theme={query.query.type}>&nbsp; {value}</BottomTextBox>;
        })
      }
      {data?.bottomText4 &&
        data.bottomText4.split('<br/>').map((value, index) => {
          if (index === 0) { return <BottomTextBox key={value} theme={query.query.type}>&#183; {value}</BottomTextBox>; }
          return <BottomTextBox key={value} theme={query.query.type}>&nbsp; {value}</BottomTextBox>;
        })
      } */}
    </>

  );
}


export const TextBox = styled('div')(({ theme }) => ({
  backgroundColor: theme === 'humanities' ? '#D1E8F6' : '#fbe7f2',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  lineHeight: 1.5,
  paddingTop: 5,
  paddingBottom: 5,
  fontSize: '1.5em',
  fontWeight: 'bold',
  color: '#411D87',
}));

export const BottomTextBox = styled('div')(({ theme }) => ({
  // backgroundColor: theme === 'humanities' ? '#D1E8F6' : '#fbe7f2',
  display: 'flex',
  textAlign: 'start',
  alignItems: 'center',
  width: '100%',
  paddingLeft: 3,
  paddingTop: 3,
  paddingBottom: 3,
  fontSize: '0.875em',
  fontWeight: 'bold'
}));

export const SlideImage = styled('img')(() => ({
  width: '20%',
  height: '20%'
}));
