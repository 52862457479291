
import { styled, Box } from '@mui/material';

export default function Footer(query) {

  return (
    <>
      <Bottom theme={query.query.type}>
        <Box sx={{margin: 0, flexWrap: 'nowrap'}}>
        전체 페이지를 포함한 교육용 게임 콘텐츠는 인용하실 때 반드시 출처를 밝혀주시기 바랍니다.<br/>

        58326 전라남도 나주시 교육길 35(빛가람동 351) 한국콘텐츠진흥원 (문의) 061-900-6511, 6510<br/>

        Copyright 2023. KOREA CREATIVE CONTENT AGENCY ALL RIGHTS RESERVED.<br/>
        </Box>


      </Bottom>
    </>

  );
}

// ----------------------------------------------------------------------

export const Bottom = styled('div')(({theme}) => ({
  backgroundColor: theme === 'humanities' ? '#411D87' : '#920784',
  textAlign: 'justify',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '200px',
  color:'white',
  fontSize:'0.9em',
  marginTop: '10px'
}));