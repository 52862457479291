import { useEffect, useState } from "react";
import { Typography } from '@mui/material';
import { styled } from '@mui/material';


export default function Header(query) {
  const [ title, setTitle ] = useState();

  useEffect(() => {
    if(query.query.type === 'nature') setTitle('자연영역'); else if (query.query.type === 'humanities') setTitle('인문영역');
  },[query])

  const jsondata = {
    imageurl: "./image/top.png"
  }
  return (
    <>
      <Banner theme={query.query.type}>
        <img src={jsondata.imageurl} height='40%' style={{ marginTop: 40 }} />
        <Typography variant="h4" sx={{ fontWeight: 'bold', padding: 2, color: 'white' }}>
          {title}
        </Typography>
      </Banner>
    </>

  );
}

// ----------------------------------------------------------------------

export const Banner = styled('div')(({theme}) => ({
  backgroundColor: theme === 'humanities' ? '#411D87' : '#920784',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '200px',
}));