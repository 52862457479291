
import Footer from './component/layout/footer';
import Header from './component/layout/header';
import Home from './page/Home'
import { styled, Paper } from '@mui/material';
import { Container, Box, height } from '@mui/system';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GameInform from './page/GameInform';
import PageLayout from './component/layout/PageLayout';
import Collection from './page/Collection';
import GameIntroLayout from './component/layout/GameIntroLayout';
import { useSearchParams } from 'react-router-dom';

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');
  const inform = searchParams.get('inform');
  const query = {
    type : type || '',
    inform : inform || '',
  }

  return (
    <Routes>
      <Route element={<PageLayout />}>
        <Route path='/' element={<Home />} />
        <Route path='/collection'  element={<Collection />} />
      </Route>
      <Route element={<GameIntroLayout query={query} />} >
        <Route path ='/game' element={<GameInform query={query} />} />
      </Route>
    </Routes>


  );
}

export default App;
