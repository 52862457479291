import React from "react";
import { Box, Typography} from '@mui/material';
import { styled } from '@mui/material';
import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import FindData from "../../util/findData";

export const GameIntroLayout = (query) => {
    const queryState = FindData(query);
    if (!queryState) return (
        <>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography> 404 Not Found</Typography>
        </Box>
        </>
    )
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
            <BoxContainer>
                <Header query={query.query} />
                <Outlet />
                <Footer query={query.query} />
            </BoxContainer>
        </Box>

    )
}

export default GameIntroLayout;

export const Headers = styled('header')(() => ({
    backgroundColor: '#D1E8F6',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '150px',
    padding: 5,
    lineHeight: 1.5,
    fontSize: '0.95em',
    fontWeight: 'bold'
}));

const BoxContainer = styled('div')(() => ({
    width: "40%",
    '@media (max-width: 800px)': {
        width: "100%"
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));