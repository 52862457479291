import { Box, Tabs, Tab, Container, Paper, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CollectionCard from "../component/CollectionCard";
import { PageInform } from "../const";

const THEME_COLOR = [
    { value: 'humanities', origin : '#397cff', light : '#559aff', dark : 'rgb(40,101,202)'},
    { value: 'nature', origin : '#42bb4c', light : '#5fcd6a', dark : 'rgb(49,153,59)'},
    { value: 'midcore', origin : '#9453c5', light : '#af72d6', dark : 'rgb(119,65,170)'},
]

export const Collection = () => {
    const [tabValue, setTabValue] = useState(0);
    const {state} = useLocation();

    useEffect(() => {
        switch (state.option) {
            case 'humanities' : setTabValue(0); break;
            case 'nature' : setTabValue(1); break;
        }
    },[state])
    

    const handleTapChange = (e, value) => {
        setTabValue(value);
    }
    return (
        <Box sx={{ flex: 1 }}>
            <Container sx={{ height: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ width: '100%', margin: 0 }}>
                    <Tabs sx={{ width: '100%', margin: 0 }} TabIndicatorProps={{ style: { background: `linear-gradient(to right, ${THEME_COLOR[tabValue].origin}, ${THEME_COLOR[tabValue].light})` } }} value={tabValue} onChange={handleTapChange} >
                        <CollectionTapHuman
                            aria-label="humanities"
                            tabprops={tabValue}
                            icon={<Box sx={{ width: '50%' }} component="img" src="/image/collection/humanities.png" />}
                        />
                        <CollectionTapNature
                            aria-label="nature"
                            tabprops={tabValue}
                            icon={<Box sx={{ width: '50%' }} component="img" src="/image/collection/nature.png" />}
                        />
						{
                        <CollectionTapMidCore
                            aria-label="nature"
                            tabprops={tabValue}
                            icon={<Box sx={{ width: '90%' }} component="img" src="/image/collection/midcore.png" />}
                        />
						}
                    </Tabs>

                </Box>
                <CollectionPage>
                    <CollectionContainer>
                        {
                            Object.entries(PageInform[THEME_COLOR[tabValue].value]).map(([key, value]) => (
                                <CollectionCard key={key} keyprops={key} inform={value} tap={tabValue} theme={THEME_COLOR} />
                            ))
                        }
                    </CollectionContainer>
                </CollectionPage>
            </Container>

        </Box>
    );
}

export default Collection;

const CollectionPage = styled(Paper)(({
    width: '100%',
    height: '60vh',
    minWidth: '300px',
    backgroundColor: 'rgba(189,195,199,0.5)',
    borderTopLeftRadius: 0,
    borderBottomRightRadius: '30px',
    borderTopRightRadius: '30px',
    borderBottomLeftRadius: '30px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',

}));

const CollectionContainer = styled('div')(({
    padding: 3,
    display: 'flex',
    height: '90%',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    overflow: 'auto',
    width: '97%',
    '&::-webkit-scrollbar': {
        width: '15px'
    },
    '::-webkit-scrollbar-track': {
        background: 'rgba(0,0,0,0.3)',
        borderRadius: '15px'
    },
    '::-webkit-scrollbar-thumb': {
        background: 'white',
        borderRadius: '15px'
    },
}));

const CollectionTapHuman = styled(Tab)(({ tabprops }) => ({
    background: tabprops === 0 ? `linear-gradient(to right, #397cff, #559aff)` : 'rgba(189,195,199,0.2)',
    height: '50px',
    width: '120px',
    '@media (max-width: 420px)': {
        width: "80px"
    },
    marginRight: '10px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
}))

const CollectionTapNature = styled(Tab)(({ tabprops }) => ({
    background: tabprops === 1 ? 'linear-gradient(to right, #42bb4c, #5fcd6a)' : 'rgba(189,195,199,0.2)',
    height: '50px',
    width: '120px',
    '@media (max-width: 420px)': {
        width: "80px"
    },
    marginRight: '10px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
}))

const CollectionTapMidCore = styled(Tab)(({ tabprops }) => ({
    background: tabprops === 2 ? 'linear-gradient(to right, #9453c5, #af72d6)' : 'rgba(189,195,199,0.2)',
    height: '50px',
    width: '120px',
    marginRight: '10px',
    '@media (max-width: 420px)': {
        width: "80px"
    },
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px'
}))